import React from "react"
import { Container, Row, Col } from 'reactstrap';
import Formsy from 'formsy-react';
import MyInput from '../components/Input';
import Textarea from '../components/Textarea';

class SectionForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { send: false, valid: false, token:''}
    this.sendEmail = this.sendEmail.bind(this);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
   
  }

  enableButton(){
    this.setState({ valid: true });
  }
  disableButton(){
    this.setState({ valid: false });
  }
  sendEmail(model) {

    model.security = 'asdd123';
    model.token  = this.state.token;
    fetch('https://libertywaterproofing.com/wordpress/sendmail.php', {
      crossDomain:true,
      mode: 'no-cors',
      headers: {'Content-Type':'application/json'},
      method: 'post',
      body: JSON.stringify(model),
    }).then(data => { 
      //this.updateToken();
      this.setState({ send: true });
      
      document.getElementById("formreset").reset();
      console.log(this.state.send);
    }); 
  }
  callback(){

  }
  render() {
    let ifsend = false;
    return (
      <Container>
      <Formsy
      onValidSubmit={this.sendEmail}
      onValid={this.enableButton}
      onInvalid={this.disableButton}
      id="formreset"
      layout=''
      className="custom-classname-is-rendered"
      validateBeforeSubmit=''
      validatePristine=''
      ref=''>

        <Row>
        <Col xl="4">
        <MyInput  
          name="name"
          id="name"
         
          value=""
      
          type="text"
          placeholder="Name"
        />
        </Col> 
        <Col xl="4">
        <MyInput  
          name="email"
          id="email"
          validations="isEmail"
          value=""
          validationError="Wrong email"
          type="email"
          placeholder="Email"
        />
        </Col>
          <Col xl="4">
        <MyInput  
          name="telefon"
          id="telefon"
          value=""
          validations="isNumeric"
          validationError="Wrong number"
          type="phone"
          placeholder="Phone"
        />
        </Col>

        </Row>
        <Row>
          <Col>
          <Textarea 
          name="wiadomosc"
          id="wiadomosc"
          value=""
         
         
        />
          </Col>

        </Row>
     
        <div className='submit-container'>
        <button type="submit" class="btn-btn" disabled={!this.state.valid}>Send</button>
        </div>
        
          
        
      </Formsy>
      </Container>
    );
  }
};
export default SectionForm;